import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Client } from '../../pages/clients/client.model';

@Injectable({
    providedIn: 'root'
})
export class CurrentClientService {

    public id: number | null;
    public client: Client | null;

    constructor(protected http: HttpClient) { }

    public setId(params: any): void {
        if (params.client_id) {
            this.id = parseInt(params.client_id, 10);
            this.getClient();
        } else {
            this.id = null;
        }
    }

    public link(): string {
        return '/clients/' + this.id;
    }

    public update(id: number): void {
        this.id = id;
        localStorage.removeItem('client');
        this.getClient();
    }

    private getClient(): void {
        let client = JSON.parse(localStorage.getItem('client'));
        if (client && this.id !== client.id) {
            client = null;
        }
        this.client = client;
        if (this.id && !client) {
            this.http.get<{data: Client}>(environment.API_URL + 'clients/' + this.id + '?with=category').subscribe(
                (res) => {
                    this.client = Client.fromJson({
                        id: res.data.id,
                        name: res.data.name,
                        type: res.data.type,
                        email: res.data.email,
                        site: res.data.site,
                        category: { name: res.data.category.name },
                        file_name: res.data.file_name,
                        responsible_name: res.data.responsible_name,
                    });
                    localStorage.setItem('client', JSON.stringify(this.client));

                    this.http.post(environment.API_URL + 'clients/accesses?noLoader=true', {
                        client_id: this.client.id,
                    }).subscribe();
                });
        }
    }
}

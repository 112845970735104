import { BaseResourceModel } from '../../shared/models/base-resource.model';
import { Category } from '../categories/category.model';
import { ClientContract } from './client-contract.model';
import { ClientProposal } from './client-proposal.model';

export class Client extends BaseResourceModel {

    constructor(
        public id?: number,
        public category_id?: number,
        public parent_id?: number,
        public type?: string,
        public code?: string,
        public name?: string,
        public company_name?: string,
        public cnpj?: string,
        public responsible_name?: string,
        public responsible_email?: string,
        public responsible_phone?: string,
        public responsible_technical?: string,
        public responsible_technical_email?: string,
        public responsible_technical_phone?: string,
        public email?: string,
        public site?: string,
        public facebook?: string,
        public instagram?: string,
        public file_name?: string,
        public street?: string,
        public number?: string,
        public complement?: string,
        public neighborhood?: string,
        public city?: string,
        public state?: string,
        public archived?: number,
        public zipcode?: string,
        public phone?: string,
        public phone_2?: string,
        public start?: string,
        public comments?: string,
        public status?: number,
        public number_employees?: number,
        public number_handlers?: number,
        public category?: Category,
        public clients_contract?: ClientContract,
        public clients_contracts?: ClientContract[],
        public clients_proposal?: ClientProposal,
        public clients_proposals?: ClientProposal[],
        public clients_contracts_count?: number,
        public clients_proposals_count?: number,
    ) {
        super();
    }

    static fromJson(jsonData: any): Client {
        return Object.assign(new Client(), jsonData);
    }
}

import { BaseResourceModel } from '../models/base-resource.model';
import { Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { CurrentClientService } from './current-client.service';
import { ListItemInterface } from '../interfaces/list-item.interface';
import { FilterSortRequestsInterface } from '../interfaces/filter-sort-requests.interface';
import { MetaPaginationInterface } from '../interfaces/fields-pagination.interface';

export abstract class BaseResourceService<T extends BaseResourceModel> {

    protected http: HttpClient;
    public apiUrl: string;
    public sidebar: ListItemInterface[];
    public params: FilterSortRequestsInterface;
    public currentClientService: CurrentClientService;

    public actionList = true;
    public actionNew  = true;
    public actionEdit = true;
    public actionView = true;

    protected constructor(
        public path: string,
        protected injector: Injector,
        protected jsonDataToResourceFn: (jsonData: any) => T
    ) {
        this.apiUrl = environment.API_URL;

        this.http = injector.get(HttpClient);
        this.currentClientService = injector.get(CurrentClientService);

        this.initParams();
    }

    public getAll(path?: string, queryParams?: FilterSortRequestsInterface): Observable<{ data: T[], meta: MetaPaginationInterface }> {
        const params = this.paramsBuilder(queryParams);
        return this.http.get(this.getUrl(path), {params}).pipe(
            map(this.jsonDataToResources.bind(this)),
            catchError(this.handleError)
        );
    }

    public getOne(path?: string, queryParams?: FilterSortRequestsInterface): Observable<T> {
        const params = this.paramsBuilder(queryParams);
        return this.http.get(this.getUrl(path), {params}).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public lists(resource?: T, path?: string, queryParams?: FilterSortRequestsInterface): Observable<{ data: any }> {
        const url = `${this.getUrl(path)}/lists`;
        const params = this.paramsBuilder(queryParams);
        if (resource) {
            return this.http.post(url, resource, {params}).pipe(
                map(this.toResource.bind(this)),
                catchError(this.handleError)
            );
        }
        return this.http.get(url, {params}).pipe(
            map(this.toResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public getById(id: number, queryParams?: FilterSortRequestsInterface): Observable<T> {
        const url = `${this.getUrl()}/${id}`;
        // const params = queryParams ? this.paramsBuilder(queryParams) : null;
        const params = this.paramsBuilder(queryParams);
        return this.http.get(url, {params}).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public create(resource: T | any, path?: string, queryParams?: FilterSortRequestsInterface): Observable<T> {
        const params = queryParams ? this.paramsBuilder(queryParams) : {};
        return this.http.post(this.getUrl(path), resource, params ? {params} : null).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public update(resource: T | any, path?: string, queryParams?: FilterSortRequestsInterface): Observable<T> {
        const url = `${this.getUrl(path)}/${resource.id}`;

        const params = queryParams ? this.paramsBuilder(queryParams) : null;
        return this.http.put(url, resource, params ? {params} : {}).pipe(
            // map(() => resource),
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public delete(id: number, path?: string, queryParams?: FilterSortRequestsInterface): Observable<any> {
        const url = path ? `${this.getUrl(path)}` : `${this.getUrl()}/${id}`;
        const params = this.paramsBuilder(queryParams);
        return this.http.delete(url, {params}).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    public bulkDelete(ids: number[], path?: string, queryParams?: FilterSortRequestsInterface): Observable<any> {
        const url = `${this.getUrl(path)}/bulk-delete`;
        const params = this.paramsBuilder(queryParams);
        return this.http.delete(url, {
                body: { ids }, ...params
            }).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    public get(path?: string, queryParams?: FilterSortRequestsInterface): Observable<any> {
        const url = `${this.getUrl(path)}`;
        const params = this.paramsBuilder(queryParams);
        return this.http.get(url, {params}).pipe(
            // map(resources => resources),
            catchError(this.handleError)
        );
    }

    public post(resource: T | any, path?: string, queryParams?: FilterSortRequestsInterface): Observable<any> {
        const url = `${this.getUrl(path)}`;
        const params = this.paramsBuilder(queryParams);
        return this.http.post(url, resource, {params}).pipe(
            // map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public put(resource: T | any, path?: string, queryParams?: FilterSortRequestsInterface): Observable<any> {
        const url = `${this.getUrl(path)}`;
        const params = this.paramsBuilder(queryParams);
        return this.http.put(url, resource, {params}).pipe(
            // map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public getUrl(anotherPath?: string): string {
        anotherPath = anotherPath === 'CLIENT' ? this.path + '/CLIENT' : anotherPath;
        return this.apiUrl + ( anotherPath ? anotherPath : this.path ).replace(
            'CLIENT', 'client/' + this.currentClientService.id
        );
    }

    public initParams(): void {
        this.params = {
            all: false,
            page: 1,
            search: null,
            searchFields: null,
            searchJoin: null,
            filter: null,
            orderBy: null,
            sortedBy: null,
            with: null,
            others: null
        };
    }

    public setSidebar(): ListItemInterface[] {
        return this.sidebar;
    }

    protected jsonDataToResources(jsonData: any[]): {data: Array<T>, meta: MetaPaginationInterface} {
        const resources: T[] = [];
        // @ts-ignore
        jsonData.data.forEach(
            element => resources.push( this.jsonDataToResourceFn(element) )
        );
        // @ts-ignore
        return {data: resources, meta: jsonData.meta};
    }

    protected jsonDataToResource(jsonData: any): T {
        return this.jsonDataToResourceFn(jsonData.data);
    }

    protected toResource(jsonData: any): any {
        return jsonData;
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }

    protected paramsBuilder(params?: FilterSortRequestsInterface): HttpParams {
        const sParams = this.searchParamsBuilder(params ? params : this.params);
        return new HttpParams({
            fromObject: (<any>sParams)
        });
    }

    protected searchParamsBuilder(params: FilterSortRequestsInterface): FilterSortRequestsInterface {
        const sParams: FilterSortRequestsInterface = {
            // page: (params.page ? params.page : 1) + '',
        };
        if (params.all) {
            sParams.all = true;
            delete sParams.page;
        }
        if (params.page) {
            sParams.page = params.page;
        }
        if (params.search && params.search !== '') {
            sParams.search = params.search;
        }
        if (params.searchFields && params.searchFields !== '') {
            sParams.searchFields = params.searchFields;
        }
        if (params.searchJoin && params.searchJoin !== '') {
            sParams.searchJoin = params.searchJoin;
        }
        if (params.filter && params.filter !== '') {
            sParams.filter = params.filter;
        }
        if (params.orderBy && params.orderBy !== '') {
            sParams.orderBy = params.orderBy;
        }
        if (params.sortedBy && params.sortedBy !== '') {
            sParams.sortedBy = params.sortedBy;
        }
        if (params.limit) {
            sParams.limit = params.limit;
        }
        if (params.with && params.with !== '') {
            sParams.with = params.with;
        }
        if (params.others) {
            // for (const item in params.others) {
            for (const item of Object.keys(params.others)) {
                sParams[item] = params.others[item];
            }
        }
        return sParams;
    }
}

import { BaseResourceModel } from './base-resource.model';
import { Account } from '../../pages/accounts/account.model';

export class Auth extends BaseResourceModel {

    constructor(
        public id?: number,
        public user_id?: number,
        public account_id?: number,
        public role?: number,
        public name?: string,
        public email?: string,
        public password?: string,
        public password_confirmation?: string,
        public token?: string,
        public provider_id?: number,
        public provider?: string,
        public provider_token?: string,
        public avatar?: string,
        public occupation?: string,
        public credential?: string,
        public credential_type?: string,
        public facebook_review?: number,
        public account?: Account,
    ) {
        super();
    }

    static fromJson(jsonData: any): Auth {

        if (jsonData.passwordConfirmation) {
            jsonData.password_confirmation = jsonData.passwordConfirmation;
            delete jsonData.passwordConfirmation;
        }

        return Object.assign(new Auth(), jsonData);
    }
}

import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Auth } from '../models/auth.model';

import { BaseResourceService } from './base-resource.service';
import { SettingsService } from './settings.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends BaseResourceService<Auth> {

    private currentUserSubject: BehaviorSubject<Auth>;
    private currentUser: Observable<Auth>;

    constructor(protected injector: Injector,
                private settingsService: SettingsService
    ) {
        super('access_token', injector, Auth.fromJson);
        this.initCurrentUser();
    }

    public get currentUserValue(): Auth {
        return this.currentUserSubject.value;
    }

    public initCurrentUser(): void {
        this.currentUserSubject = new BehaviorSubject<Auth>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public login(resource: Auth): Observable<Auth> {
        return this.http.post<{data: Auth}>(this.getUrl(), resource).pipe(
            map(user => {
                this.setCurrentUser(user.data);
                return user.data;
            }),
            catchError(this.handleError)
        );
    }

    public socialLogin(resource: Auth): Observable<Auth> {
        return this.http.post<{data: Auth}>(this.getUrl('login/' + resource.provider + '/callback'), resource).pipe(
            map(user => {
                this.setCurrentUser(user.data);
                return user.data;
            }),
            catchError(this.handleError)
        );
    }

    public refreshToken(): Observable<any> {
        return this.http.post<{data: Auth}>(this.getUrl('refresh_token'), []).pipe(
            map(user => {
                this.setNewToken(user.data.token);
                return user.data.token;
            }),
            catchError(this.handleErrorLogout)
        );
    }

    public setNewToken(token: string): void {
        const user = this.currentUserValue;
        if (user) {
            user.token = token;
            this.setCurrentUser(user);
        }
    }

    public logout(): Observable<any> {
        return this.http.post(this.getUrl('logout'), []).pipe(
            map(() => this.removeStorageCurrentUser()),
            catchError(this.handleErrorLogout)
        );
    }

    public removeStorageCurrentUser(): void {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('subscriber');
        localStorage.removeItem('situation');
        localStorage.removeItem('plan');
        localStorage.removeItem('client');
        localStorage.removeItem('css');
        localStorage.removeItem('reportColor');
        localStorage.removeItem('fcShowIndicators');
        localStorage.removeItem('fcSelectedIndicatorsPeriod');
        this.currentUserSubject.next(null);
    }

    public forgot(resource: Auth): Observable<Auth> {
        return this.http.post<Auth>(this.getUrl('password/email'), resource).pipe(
            map(() => resource),
            catchError(this.handleError)
        );
    }

    public reset(resource: Auth): Observable<Auth> {
        return this.http.post<Auth>(this.getUrl('password/reset'), resource).pipe(
            map(() => resource),
            catchError(this.handleError)
        );
    }

    public register(resource: Auth): Observable<Auth> {
        return this.http.post<Auth>(this.getUrl('register'), resource).pipe(
            map(() => resource),
            catchError(this.handleError)
        );
    }

    public resendEmail(resource: Auth): Observable<Auth> {
        return this.http.post<Auth>(this.getUrl('register/resend'), resource).pipe(
            map(() => resource),
            catchError(this.handleError)
        );
    }

    public activate(token: string, email?: string): Observable<Auth> {
        let url = 'activate/' + token;
        if (email) { url += '/' + email; }
        return this.http.get<{data: Auth}>(this.getUrl(url)).pipe(
            map(user => {
                if (user) {
                    this.setCurrentUser(user.data);
                    return user.data;
                }
                return null;
            }),
            catchError(this.handleError)
        );
    }

    public setCurrentUser(data: Auth): void {
        this.settingsService.setSettings(data.account.settings);
        localStorage.setItem('currentUser', JSON.stringify(data));
        document.cookie = 'fc_current_user=' + JSON.stringify({
                id: data.id,
                name: data.name,
                subscriber: localStorage.getItem('subscriber'),
            }) + '; expires=' + new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toUTCString() + '; path=/; domain=.foodchecker.com.br';
        if (data.account.color) {
            localStorage.setItem('reportColor', data.account.color);
        }
        localStorage.setItem('language', data.account.language ? data.account.language : 'pt_BR');
        this.currentUserSubject.next(data);
    }

    public completeProfile(): boolean {
        return !this.currentUserValue.credential && !this.currentUserValue.occupation;
    }

    protected handleErrorLogout(error: any): Observable<any> {
        this.removeStorageCurrentUser();
        return throwError(error);
    }
}
